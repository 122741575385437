.all-psk-reporter-viewer {
    padding: 50px;
}

.all-psk-reporter-viewer h1 {
    margin-bottom: 20px;
}

.column-toggle {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.column-toggle label {
    display: flex;
    align-items: center;
    font-size: 14px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    cursor: pointer;
}

tr:hover {
    background-color: #f1f1f1;
}

.all-psk-reporter-viewer .line-through {
    text-decoration: line-through;
}

.darkpurple {
    background-color: #4B0082; /* Example dark purple color */
}

.lightpurple {
    background-color: #9370DB; /* Example light purple color */
}