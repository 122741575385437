/* src/styles/Register.css */
.register-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;  /* Optional: add a background color */
  }
  
  .register-container h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px 12px;  /* Added padding to the right side */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;  /* Ensure padding does not affect total width */
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .message {
    margin-top: 10px;
    color: green;
  }
  
  .error {
    margin-top: 10px;
    color: red;
  }
  