.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust height as needed */
  padding: 20px;
}

.propagation-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1, h2 {
  text-align: center;
}

.general-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  padding: 10px;
}

.general-info p {
  margin: 5px 0;
}

.conditions-section {
  margin-top: 20px;
}

.conditions-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 10px;
  padding: 10px;
}

.condition-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  color: #000;
  background-color: #f5f5f5; /* Light grey background for readability */
}

.conditions-section .condition-item {
  color: #000;
  font-weight: bold;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}
