.weather-forecast {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.forecast-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forecast-day {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.forecast-day h3 {
  margin: 0 0 10px;
}

.forecast-day p {
  margin: 5px 0;
}

.forecast-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.forecast-item {
  background-color: #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  text-align: center;
  width: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.forecast-item p {
  margin: 5px 0;
}

.forecast-item img {
  margin-top: 10px;
}
