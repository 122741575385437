/* src/components/AddCluster/AddCluster.css */
.add-cluster-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .add-cluster-container h3 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .add-cluster-container .form-group {
    margin-bottom: 15px;
  }
  
  .add-cluster-container .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .add-cluster-container .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-cluster-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .add-cluster-container button:hover {
    background-color: #0056b3;
  }
  
  .add-cluster-container p {
    margin-top: 15px;
    text-align: center;
  }
  