/* src/styles/Dashboard.css */
.dashboard-container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-section {
  width: 100%;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
}

.propagation-container,
.dx-cluster-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}

/* src/styles/Dashboard.css */
.dashboard-section iframe {
  max-width: 100%;
  border-radius: 8px;
}
