/* SensorDataChart.css */

.sensor-data-container {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.sensor-data-header {
  text-align: center;
  margin-bottom: 20px;
}

.date-picker-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.date-picker {
  margin: 0 10px;
}

.chart-container {
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  height: 500px;
}

.nivo-line-chart {
  height: 100%;
}

/* Custom styles for the nivo chart */
.nivo-axis text {
  font-size: 12px;
  fill: #555;
}

.nivo-axis path {
  stroke: #ccc;
}

.nivo-legends {
  font-size: 12px;
  fill: #555;
}

.nivo-line {
  stroke-width: 2px;
}

.nivo-tooltip {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.tooltip {
  font-size: 12px;
}

.tooltip div {
  margin-bottom: 5px;
}
