/* src/styles/global.css */
body, html, #root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  height: 100vh;
}

.side-nav-paper {
  width: 20% !important;
}

.main-content {
  width: 80%;
  padding: 100px;
  overflow-y: auto;
}
