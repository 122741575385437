.qso-viewer {
    padding-top: 50px; /* Adjust the value as needed */
}

.qso-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.qso-table th,
.qso-table .td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.qso-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.qso-table .tr:nth-child(even) .td {
    background-color: #f9f9f9;
}

.qso-table .tr:hover .td {
    background-color: #f1f1f1;
}

.table-container {
    position: relative;
}

.table-body {
    width: 100%;
    overflow: auto;
}

.tr {
    display: flex;
    width: 100%;
}

.td {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination button {
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
}

.column-toggle {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.column-toggle label {
    display: flex;
    align-items: center;
    gap: 5px;
}
