/* src/components/SideNav.css */
.side-nav-paper {
    width: 240px;
    background-color: #f4f4f4;
  }
  
  .MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  