.dx-cluster-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Allow vertical scrolling */
  height: calc(100vh - 150px); /* Adjust height to fit the viewport */
}

.dx-cluster-search {
  margin-bottom: 20px;
  padding: 8px;
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.table-wrapper {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
  position: sticky;
  top: 64px; /* Adjust this value to match the height of your header */
  z-index: 1;
}

tr:hover {
  background-color: #f1f1f1;
}

.error {
  color: red;
  margin-top: 10px;
}
