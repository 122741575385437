.callsign-monitoring {
    padding: 50px;
}

.callsign-monitoring h1,
.callsign-monitoring h2 {
    text-align: center;
}

.callsign-monitoring ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
}

.callsign-monitoring ul li {
    display: inline;
    margin-right: 10px;
    font-size: 18px;
}

.callsign-monitoring form {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.callsign-monitoring form input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
}

.callsign-monitoring form button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
}

.qso-table {
    width: 100%;
    border-collapse: collapse;
}

.qso-table th,
.qso-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.qso-table th {
    background-color: #f2f2f2;
}

.qso-table tr:hover {
    background-color: #f1f1f1;
}

.refresh-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 0;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.refresh-button:hover {
    background-color: #0056b3;
}
