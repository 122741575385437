.irrigation-control {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px auto;
  width: 80%;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sensor-data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.sensor-item p {
  font-size: 16px;
  margin: 5px 0;
}

.control-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.control-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

.control-buttons button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}
